import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout.js"
import { Link } from "gatsby"

export default function Category({ data, location, pageContext }) {
  var selectedCategory = pageContext.category

  var valid_products = []
  data.allMongodbStoreProducts.edges.forEach(node => {
    if (node.node.categories.includes(selectedCategory)) {
      valid_products.push(node)
    }
  })

  return (
    <Layout
      title={selectedCategory}
      header={selectedCategory}
      location={location}
      pageContext={pageContext}
      addCrumbs={true}
      description={selectedCategory}
    >
      <h1>{selectedCategory}</h1>
      <div id="categoryWrapper">
        <ul>
          {valid_products.map(({ node }, index) => (
            <li key={index}>
              <Link to={"/produkter/" + node.fields.full_name_url + "/"}>
                {node.full_name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMongodbStoreProducts(sort: { fields: [brand, full_name] }) {
      edges {
        node {
          id
          full_name
          brand
          categories
          fields {
            full_name_url
            brand_url
          }
        }
      }
    }
  }
`
